import core from '@/admin/core';
import { reactive, ref } from '@vue/composition-api';

const adminDomainName = (process.env.VUE_APP_API_ENDPOINT as string).replace('https://api.', '');
interface SettingEmailTemplates {
  [key: string]: {
    url?: string;
    body: string;
    subject: string;
  };
}
export interface SettingEmail {
  settingId: string;
  value: {
    signature: string;
    templates: SettingEmailTemplates;
  };
}
export interface SettingEmailProps {
  signature: string;
  templates: SettingEmailTemplates;
}

export interface SettingUserProperties {
  settingId: string;
  value: {
    isAllowUpdateProperties: boolean;
  };
}
export interface SettingUserPropertiesProps {
  isAllowUpdateProperties: boolean;
}

export interface ConsoleMenuProperty {
  title: string;
  icon: string;
  to: string;
  isActive: boolean;
}
export interface SettingConsoleMenu {
  settingId: string;
  value: ConsoleMenuProperty[];
}

export interface WysiwygProps {
  isDarkMode: boolean;
}
export interface SettingWysiwyg {
  settingId: string;
  value: WysiwygProps;
}

const DefaultConsoleMenuSetting: SettingConsoleMenu = {
  settingId: 'consoleMenu',
  value: [
    {
      title: 'お知らせ',
      icon: 'mdi-bell',
      to: '/announcements',
      isActive: true,
    },
    {
      title: 'ユーザー',
      icon: 'mdi-account-multiple',
      to: '/users',
      isActive: true,
    },
    {
      title: 'プラン加入状況',
      icon: 'mdi-history',
      to: '/histories',
      isActive: true,
    },
    {
      title: '退会理由',
      icon: 'mdi-account-off',
      to: '/leave',
      isActive: true,
    },
    {
      title: 'マイトーク',
      icon: 'mdi-forum',
      to: '/mytalks',
      isActive: true,
    },
    {
      title: 'トークルーム',
      icon: 'mdi-chat',
      to: '/talkrooms',
      isActive: true,
    },
    {
      title: 'メッセージBOX',
      icon: 'mdi-message-badge',
      to: '/messages',
      isActive: true,
    },
    {
      title: '誕生日メッセージ',
      icon: 'mdi-cake',
      to: '/birthday',
      isActive: false,
    },
    {
      title: '動画',
      icon: 'mdi-movie-settings',
      to: '/videos',
      isActive: false,
    },
    {
      title: 'スケジュール',
      icon: 'mdi-calendar-month',
      to: '/schedules',
      isActive: false,
    },
    {
      title: 'フォーム',
      icon: 'mdi-clipboard-text-outline',
      to: '/forms',
      isActive: true,
    },
    {
      title: '特典',
      icon: 'mdi-heart',
      to: '/gifts',
      isActive: false,
    },
    {
      title: '個別課金',
      icon: 'mdi-basket',
      to: '/products',
      isActive: false,
    },
    {
      title: '閲覧権限',
      icon: 'mdi-archive-eye',
      to: '/permissions',
      isActive: true,
    },
    {
      title: '会員証',
      icon: 'mdi-card',
      to: '/membershipcards',
      isActive: true,
    },
    {
      title: '推しメン設定',
      icon: 'mdi-account-heart',
      to: '/favorites',
      isActive: false,
    },
    {
      title: 'プラン',
      icon: 'mdi-book-open-outline',
      to: '/plans',
      isActive: true,
    },
    {
      title: 'キャンペーン',
      icon: 'mdi-calendar-star',
      to: '/campaigns',
      isActive: false,
    },
    {
      title: '会員登録項目',
      icon: 'mdi-account',
      to: '/properties',
      isActive: false,
    },
    {
      title: '公式ユーザー',
      icon: 'mdi-account-check',
      to: '/officialusers',
      isActive: true,
    },
    {
      title: 'テストユーザー',
      icon: 'mdi-account-hard-hat',
      to: '/testusers',
      isActive: true,
    },
    {
      title: 'リリース設定',
      icon: 'mdi-pencil-lock',
      to: '/release',
      isActive: true,
    },
    {
      title: '設定',
      icon: 'mdi-cog',
      to: '/settings',
      isActive: true,
    },
    {
      title: '管理者',
      icon: 'mdi-shield-half-full',
      to: '/administrators',
      isActive: true,
    },
    {
      title: '管理者権限',
      icon: 'mdi-shield-account',
      to: '/roles',
      isActive: false,
    },
    {
      title: '機能連携',
      icon: 'mdi-launch',
      to: '/links',
      isActive: true,
    },
    {
      title: 'アカウント',
      icon: 'mdi-lock',
      to: '/account',
      isActive: true,
    },
    {
      title: 'レポート',
      icon: 'mdi-chart-bar',
      to: '/report',
      isActive: true,
    },
    {
      title: '画像・動画販売',
      icon: 'mdi-image-edit',
      to: `https://v2.${adminDomainName}/digitalcontents`,
      isActive: false,
    },
  ],
};

export const getSettingEmail = async () => {
  const result = await core.httpClient.get('/admin/public/settings/email');
  return result.data as SettingEmail;
};

export const getSettingUserProperties = async () => {
  const result = (await core.httpClient.get('/admin/public/settings/userProperties')).data as SettingUserProperties;
  if (result) {
    return result;
  } else {
    const props: SettingUserProperties = {
      settingId: 'userProperties',
      value: {
        isAllowUpdateProperties: false,
      },
    };
    await core.httpClient.post('/admin/public/settings', props);
    return props;
  }
};

export const getSettingConsoleMenu = async () => {
  const result = (await core.httpClient.get('/admin/public/settings/consoleMenu')).data as SettingConsoleMenu;
  if (result) {
    // デフォルトのメニューを選択肢に追加する
    DefaultConsoleMenuSetting.value.forEach((menu) => {
      if (!result.value.some((item) => item.to === menu.to)) {
        result.value.push(menu);
      }
    });
    return result;
  } else {
    await core.httpClient.post('/admin/public/settings', DefaultConsoleMenuSetting);
    return DefaultConsoleMenuSetting;
  }
};

export const getSettingWysiwyg = async () => {
  const result = (await core.httpClient.get('/admin/public/settings/wysiwyg')).data as SettingWysiwyg;
  if (result) {
    return result;
  } else {
    const props: SettingWysiwyg = {
      settingId: 'wysiwyg',
      value: {
        isDarkMode: false,
      },
    };
    await core.httpClient.post('/admin/public/settings', props);
    return props;
  }
};

export const emailTypeOptions = ref([
  {
    text: '仮会員登録',
    value: 'preSignup',
  },
  {
    text: '会員登録',
    value: 'signup',
  },
  {
    text: 'アカウント情報変更',
    value: 'updateAttributes',
  },
  {
    text: 'メールアドレス変更',
    value: 'updateEmail',
  },
  {
    text: 'メールアドレス変更完了',
    value: 'updateEmailVerify',
  },
  {
    text: 'パスワード変更',
    value: 'updatePassword',
  },
  {
    text: 'パスワード再設定',
    value: 'remindPassword',
  },
  {
    text: 'パスワード再設定完了',
    value: 'remindPasswordVerify',
  },
  {
    text: '退会完了',
    value: 'leave',
  },
  {
    text: 'メルマガ変更',
    value: 'updateMailmagazineSubscription',
  },
  {
    text: 'プラン加入（会員登録時）',
    value: 'updatePaymentSubscriptionAtSignup',
  },
  {
    text: 'プラン加入',
    value: 'updatePaymentSubscription',
  },
  {
    text: 'トライアルプラン加入（会員登録時）',
    value: 'updateTrialPaymentSubscriptionAtSignup',
  },
  {
    text: 'トライアルプラン加入',
    value: 'updateTrialPaymentSubscription',
  },
  {
    text: 'プラン解約',
    value: 'cancelPaymentSubscription',
  },
  {
    text: 'プラン継続',
    value: 'resumePaymentSubscription',
  },
  {
    text: 'プラン決済エラー',
    value: 'updatePaymentSubscriptionFailed',
  },
  {
    text: '商品購入',
    value: 'purchaseProduct',
  },
  {
    text: 'メッセージBOX通知',
    value: 'notification',
  },
  {
    text: 'マイトークの未読通知',
    value: 'unreadChatMessageCount',
  },
  {
    text: 'お届け先変更完了',
    value: 'updateShippingAddressOfPurchaseProduct',
  },
]);

export class Settings {
  fetchLoading = false;
  email: SettingEmail = {
    settingId: '',
    value: {
      signature: '',
      templates: {},
    },
  };
  settingUserProperties: SettingUserProperties = {
    settingId: '',
    value: {
      isAllowUpdateProperties: false,
    },
  };
  settingConsoleMenu: SettingConsoleMenu = {
    settingId: '',
    value: [],
  };
  settingWysiwyg: SettingWysiwyg = {
    settingId: '',
    value: {
      isDarkMode: false,
    },
  };

  constructor() {
    this.init();
  }

  init = async () => {
    this.fetchLoading = true;
    [this.email, this.settingUserProperties, this.settingConsoleMenu, this.settingWysiwyg] = await Promise.all([
      getSettingEmail(),
      getSettingUserProperties(),
      getSettingConsoleMenu(),
      getSettingWysiwyg(),
    ]);
    Object.keys(this.email.value.templates).forEach((key) => {
      if (!emailTypeOptions.value.find((option) => option.value === key))
        emailTypeOptions.value.push({ text: key, value: key });
    });
    this.fetchLoading = false;
  };

  saveSettings = async () => {
    const emailProps: SettingEmailProps = {
      signature: this.email.value.signature,
      templates: {},
    };
    Object.entries(this.email.value.templates).forEach(([key, value]) => (emailProps.templates[key] = value));

    const userProperties: SettingUserPropertiesProps = {
      isAllowUpdateProperties: this.settingUserProperties.value.isAllowUpdateProperties,
    };

    await Promise.all([
      core.httpClient.put('/admin/public/settings/userProperties', userProperties).then(
        (result) => result,
        () => console.log('userProperties失敗')
      ),
      core.httpClient.put('/admin/public/settings/email', emailProps).then(
        (result) => result,
        () => console.log('email失敗')
      ),
      core.httpClient.put('/admin/public/settings/consoleMenu', this.settingConsoleMenu.value).then(
        (result) => result,
        () => console.log('menu失敗')
      ),
      core.httpClient.put('/admin/public/settings/wysiwyg', this.settingWysiwyg.value).then(
        (result) => result,
        () => console.log('wysiwyg失敗')
      ),
    ]);
  };

  validate = () => {
    let result = {
      isValid: true,
      errorMessage: '',
    };
    if (
      !this.email.value.signature &&
      !this.email.value.templates.signup.subject &&
      !this.email.value.templates.signup.body &&
      !this.email.value.templates.preSignup.subject &&
      !this.email.value.templates.preSignup.body
    )
      result = { isValid: false, errorMessage: '未入力があります。' };
    return result;
  };
}

export const useSettings = () => {
  const settings = new Settings();
  return reactive(settings);
};
